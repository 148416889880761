import React, { FC, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Carousel: FC<{
  items?: Array<object>;
  onIndexChange: (arg: number) => void;
}> = ({ items, onIndexChange }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const indicators = (index) => <div className="indicator"></div>;

  const indexHelper = (oldIndex, newIndex) => {
    if (oldIndex > items.length - 1) {
      onIndexChange(0);
      setImageIndex(0);
    } else {
      onIndexChange(newIndex);
      setImageIndex(newIndex);
    }
  };

  const navigation = {
    prevArrow: (
      <button className="carouselNav">
        <span className="arrow__left arrow ml05 block cb rel carouselNav__left" />
      </button>
    ),
    nextArrow: (
      <button className="carouselNav">
        <span className="arrow__right arrow ml05 block cb rel carouselNav__right" />
      </button>
    )
  };

  return (
    <>
      <div className="slide-container">
        <Slide
          {...navigation}
          indicators={indicators}
          duration={4000}
          transitionDuration={500}
          onChange={(oldIndex, newIndex) => indexHelper(oldIndex, newIndex)}
        >
          {items.map((item, i) => (
            <div key={i} className="each-slide-effect">
              <img src={item.original} />
            </div>
          ))}
        </Slide>
      </div>
    </>
  );
};

export default Carousel;
